.scrollhost::-webkit-scrollbar {
    display: none;
}

.scrollhost-container {
    position: relative;
    height: 100vh;
    width: 100%;
}
.scrollhost {
    overflow: auto;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
}

.scroll-bar {
    width: 10px;
    right: 0;
    top: 0;
    position: absolute;
    border-radius: 7px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 9999;
}

.scroll-thumb {
    width: 8px;
    height: 20px;
    margin-left: 1px;
    position: absolute;
    border-radius: 7px;
    opacity: 1;
    top: 0;
    background-color: rgba(0, 0, 0, 0.55);
}
